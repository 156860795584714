import React from 'react';
import './Services.css';

function Services() {

	const images = [
		require('../images/board/Image1.png'),
		require('../images/board/Image2.png'),
		require('../images/board/Image3.png'),
		require('../images/board/Image4.png'),
		// ... add more images
	];

	return (
		<div className="services">
			<div className="gallery-container">
				{images.map((image, index) => (
					<img
						key={index}
						src={image}
						alt={`Gallery item ${index + 1}`}
						className="gallery-image"
					/>
				))}
			</div>
		</div>
	)
}

export default Services;