// components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>BAT</h3>
          <p>Registered 501(c)(3) Organization</p>
        </div>

        <div className="footer-section">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#gallery">Gallery</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Contact Info</h4>
          <ul className="contact-info">
            <li>
              <i className="fas fa-phone"></i>
              <span>(707) JOIN BAT</span>
            </li>
            <li>
              <i className="fas fa-envelope"></i>
              <span>support@batus.org</span>
            </li>
            <li>
              <i className="fas fa-location-dot"></i>
              <span>PO Box 1396 , 8700 Stonebrook pkwy , Frisco TX 75035</span>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Follow Us</h4>
          <div className="social-links">
		  <a href="https://www.facebook.com/batus.vipras" className="hover:text-primary">
                <i className="fab fa-facebook text-2xl"></i>
              </a>
              <a href="https://www.instagram.com/batus.vipra" className="hover:text-primary">
                <i className="fab fa-instagram text-2xl"></i>
              </a>
              <a href="https://x.com/batus_vipras" className="hover:text-primary">
                <i className="fab fa-twitter text-2xl"></i>
              </a>
              <a href="https://www.youtube.com/@batusvipras" className="hover:text-primary">
                <i className="fab fa-youtube text-2xl"></i>
              </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="copyright">
          <p>&copy; {currentYear} Brahmin Association of Texas. All rights reserved.</p>
        </div>
        <div className="footer-links">
          <a href="#privacy">Privacy Policy</a>
          <a href="#terms">Terms of Service</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
