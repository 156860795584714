// components/Home.js
import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Core Mission</h1>
          <p className="hero-subtitle">Empowering the present and future generations on <br/>
		  the <i>Sanatana Dharma</i> culture, tradition & values.</p>
        </div>
      </section>

      {/* About Section */}
      <section className="about-section">
        <div className="section-content">
          <h2>About Us</h2>
          <p>Volunteer run non-profit organization dedicated to support the community and bring awareness on Sanatana Dharma way of life, which would help every citizen to lead a principled life with peace and harmony.
			Creating a physical and virtual community for Brahmanas to come together and celebrate our culture.</p>
          <div className="stats-container">
            <div className="stat-item">
              <span className="stat-number">200+</span>
              <span className="stat-label">Member Families</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">100+</span>
              <span className="stat-label">Adult & Youth Volunteers</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">10+</span>
              <span className="stat-label">Platinum & Gold Sponsors</span>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="services-section">
        <div className="section-content">
          <h2>Mission Objectives</h2>
          <div className="services-grid">
            <div className="service-card">
              <div className="service-icon">🎵</div>
              <h3>Community Service</h3>
              <p>Imparting the service-orientedness nature in the youth</p>
            </div>
            <div className="service-card">
              <div className="service-icon">🎉</div>
              <h3>Cultural Events</h3>
              <p>Preserving and Celebrating the culture</p>
            </div>
            <div className="service-card">
              <div className="service-icon">💡</div>
              <h3>Support</h3>
              <p>Being there in times of need for the community</p>
            </div>
          </div>
        </div>
      </section>

      {/* Equipment Section */}
      <section className="equipment-section">
        <div className="section-content">
          <h2>BAT Matrimony</h2>
          <div className="equipment-grid">
            <div className="equipment-item">
              <h3>Best matches</h3>
              <p>You will find the best matches to your need</p>
            </div>
            <div className="equipment-item">
              <h3>Verified Profiles</h3>
              <p>BAT Matrimony does thorough validations & verfications of profiles</p>
            </div>
            <div className="equipment-item">
              <h3>100% Privacy</h3>
              <p>Privacy is the number one priority</p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="contact-section">
        <div className="section-content">
          <h2>Join BAT</h2>
          <p>Join / Donate to support BAT</p>
          <button className="cta-button primary">Join BAT</button>
        </div>
      </section>
    </div>
  );
}

export default Home;
