// components/Gallery.js
import React, { useState, useEffect, useCallback } from 'react';
import './Gallery.css';

function Gallery() {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Import all images from the specified directory
    const importAll = (r) => {
      return r.keys().map(r);
    };

    // Get all files that end with .png, .jpg, or .jpeg from the images folder
    const imageContext = require.context('../images/events', false, /\.(png|jpe?g)$/);
    const loadedImages = importAll(imageContext);
    
    setImages(loadedImages);
  }, []);


  // Memoize the nextSlide function with useCallback
  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => 
      prev === images.length - 1 ? 0 : prev + 1
    );
  }, [images.length]);

  // Auto-play functionality with nextSlide in dependencies
  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer);
  }, [nextSlide]); // Add nextSlide to dependencies

  const prevSlide = () => {
    setCurrentSlide((prev) => 
      prev === 0 ? images.length - 1 : prev - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="gallery-container">
      <h2>Our Event Gallery</h2>
      
      <div className="slideshow-container">
        {/* Main Slide */}
        <div className="slide">
          <img 
            src={images[currentSlide]} 
            alt="img"
          />
          
        </div>

        {/* Navigation Arrows */}
        <button className="prev" onClick={prevSlide}>❮</button>
        <button className="next" onClick={nextSlide}>❯</button>

        {/* Dots/Indicators */}
        <div className="dots-container">
          {images.map((_, index) => (
            <span 
              key={index}
              className={`dot ${currentSlide === index ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>

      {/* Thumbnail Preview */}
      <div className="thumbnails">
        {images.map((item, index) => (
          <div 
            key={index}
            className={`thumbnail ${currentSlide === index ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          >
            <img src={item} alt="img" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
