// components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <nav className="navbar">
      <div className="nav-left">
        <img src="/logo.png" alt="Logo" className="nav-logo" />
        <Link to="/" className="nav-brand">Brahmin Association of Texas
		<br/> Registered Nonprofit 501(c)(3)
		</Link>
      </div>
      
      <div className="nav-right">
        <div className="nav-links">
          <Link to="/">Home</Link>
          <Link to="/services">BAT Board</Link>
          <Link to="/gallery">Gallery</Link>
          <Link to="/contact">Contact</Link>
        </div>
        
        <div className="dropdown">
          <button  className="dropdown-button">
            Donate Now
          </button>
          {dropdownOpen && (
            <div className="dropdown-content">
              <Link to="/profile">Profile</Link>
              <Link to="/settings">Settings</Link>
              <Link to="/logout">Logout</Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
